<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Home",
  components: {},
  mounted() {
  },
};
</script>
